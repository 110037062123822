import Vue from 'vue';
import { Route } from 'vue-router';
import Vuex from 'vuex';
import Recipe from '@/types/recipe/Recipe';
// import BackendService from '@/services/backend';
import CmsBackend from '@/services/cms-backend';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    breadcrumbs: [] as Route[],
    recipes: [] as Recipe[],
    search: '',
  },
  mutations: {
    setBreadcrumbs(state, routes: Route[]) {
      state.breadcrumbs = routes;
    },
    addBreadcrumb(state, route: Route) {
      state.breadcrumbs.push(route);
    },
    popBreadcrumb(state) {
      state.breadcrumbs = state.breadcrumbs.slice(0, -1);
    },
    setRecipes(state, recipes: Recipe[]) {
      state.recipes = recipes;
    },
    addRecipes(state, recipes: Recipe[]) {
      state.recipes = state.recipes.concat(recipes);
    },
    setSearch(state, search: string) {
      state.search = search;
    },
  },
  actions: {
    setBreadcrumbs(context, routes: Route[]) {
      context.commit('setBreadcrumbs', routes);
    },
    addBreadcrumb(context, route: Route) {
      context.commit('addBreadcrumb', route);
    },
    popBreadcrumb(context) {
      context.commit('popBreadcrumb');
    },
    saveSearch(context, search: string) {
      context.commit('setSearch', search);
    },
    async getRecipes(context, page = 0) {
      // const recipes = await BackendService.getRecipes(page);
      const recipes = await CmsBackend.getRecipes(page);
      context.commit('setRecipes', recipes);
    },
    async searchRecipes(context, search) {
      if (search === '') {
        this.dispatch('getRecipes');
      } else {
        const recipes = await CmsBackend.searchRecipes(search);
        context.commit('setRecipes', recipes);
      }
    },
  },
  modules: {
  },
});
