
















import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component({
  filters: {
    cleanPath: (path: string) => {
      const pieces = path.split('/');
      return pieces[pieces.length - 1].replaceAll('-', ' ');
    },
  },
})
export default class Breadcrumbs extends Vue {
  addSearchToRecipePath(path: string) {
    if (path !== '/recipes' || this.$store.state.search.length === 0) {
      return path;
    }
    return `${path} search: "${this.$store.state.search}"`;
  }

  get breadcrumbs(): Route[] {
    return this.$store.state.breadcrumbs;
  }
}
