import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import RecipesPage from '../views/RecipesPage.vue';
import RecipePage from '../views/RecipePage.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import AboutThisWebsite from '../views/AboutThisWebsite.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: RecipesPage,
    beforeEnter: (to, from, next) => {
      store.dispatch('setBreadcrumbs', [to]);
      next();
    },
  },
  {
    path: '/recipes/:slug',
    name: 'Recipe',
    props: true,
    component: RecipePage,
    beforeEnter: (to, from, next) => {
      store.dispatch('addBreadcrumb', to);
      next();
    },
  },
  {
    path: '/privacy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta: { showInNav: false }
    
  },{
    path: '/about-this-website',
    name: 'about-this-website',
    component: AboutThisWebsite,
    meta: { showInNav: false }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
