import { DateTime } from 'luxon';
import { Ingredient } from './Ingredient';
import { Instruction } from './Instruction';

/* eslint-disable camelcase */
export interface RecipeInterface {
  slug: string;
  title: string;
  imageURL: string;
  description: string;
  created: string;
  prepMinutes: number;
  cookMinutes: number;
  totalMinutes: number;
  servings: number;
  servingAmount: number;
  servingCalories: number;
  recipeIngredients: any[];
}

export default class Recipe {
  public amountOfIngredients: number = 0;
  
  public coverUrl: string;

  public description: string;

  public servings: number;
  public servingDescription: string;

  public slug: string;

  public title: string;

  public minutes: number;

  public updatedAt: DateTime;

  public content: any | undefined;

  public ingredients: Ingredient[] = [];
  
  public instructions: Instruction[] = [];

  constructor(
    amountOfIngredients: number,
    coverUrl: string,
    description: string,
    servings: number,
    servingDescription: string,
    slug: string,
    title: string,
    minutes: number | undefined,
    // timeDescription: string,
    updatedAt: DateTime | undefined,
  ) {
    this.amountOfIngredients = amountOfIngredients;
    this.coverUrl = coverUrl;
    this.description = description;
    this.servings = servings;
    this.servingDescription = servingDescription;
    this.slug = slug;
    this.title = title;
    this.minutes = minutes || 0;
    // this.timeDescription = timeDescription;
    this.updatedAt = updatedAt || DateTime.local();
  }

  setIngredients(ingredients: any[]): Recipe {
    this.ingredients = ingredients;
    return this;
  }

  setInstructions(instructions: any[]): Recipe {
    this.instructions = instructions;
    return this;
  }
}
