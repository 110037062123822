




























import Vue from 'vue';

export default Vue.component('recipe-searchbar', {
  props: {
    suggestions: [],
  },
  data() {
    return {
      search: '',
      suggestionsOpen: false,
      selected: '',
    };
  },
  mounted() {
    this.search = this.$store.state.search;
    if (this.search.length > 0) {
      this.$emit('submitted', this.search);
    }
  },
  methods: {
    renderSuggestion(suggestion: any) {
      const colors: any = {
        title: 'green',
        ingredients: 'blue',
        description: 'indigo',
        default: 'orange',
      };

      const tags = suggestion.item.matchedOn.map((item: string) => this.$createElement(
        'div',
        { class: `text-gray-300 text-sm bg-${colors[item]}-800 rounded px-2 ml-2` },
        item,
      ));

      const currentItem = suggestion.item.slug == this.selected;

      return this.$createElement(
        'div',
        { class: `hover:bg-gray-400 hover:dark:bg-gray-500 p-3 pt-2 w-100 rounded drop-shadow
          ${currentItem ? ' bg-gray-400 dark:bg-gray-500' : ''}` },
        [
          suggestion.item.title,
          this.$createElement(
            'div',
            { class: 'flex flex-wrap items-center justify-items-center dark:text-gray-400 text-gray-600 text-sm' },
            ['result in:', ...tags],
          ),
        ],
      );
    },
    itemKeyed(suggestionItem: any, index: number) {
      this.selected = suggestionItem.item.slug;
    },
    getSuggestionValue(suggestion: any) {
      return suggestion.item.title;
    },
    fetchResults() {
      this.$emit('fetchResults', this.search);
    },
    selectItem(suggestionItem: any, index: number) {
      this.$store.dispatch('saveSearch', this.search);
      this.$emit('selected', suggestionItem, index);
    },
    submitted() {
      this.$store.dispatch('saveSearch', this.search);
      this.$emit('submitted', this.search);
    },
  }
});
