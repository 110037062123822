import axios from 'axios';
import { Client } from 'typesense';
import { ContentfulClientApi, createClient } from 'contentful';
import { DateTime } from 'luxon';


import Recipe from '@/types/recipe/Recipe';
import { Ingredient } from '@/types/recipe/Ingredient';
import { Instruction } from '@/types/recipe/Instruction';

export default class CmsBackend {
  public static async getTextContent(routePath: string): Promise<any> {
    const client = CmsBackend.createContentfulClient();

    const entries = await client.getEntries<any>({
      'fields.route': routePath.toLowerCase(),
      content_type: 'textContent',
    });
    
    if (entries.total === 0) {
      return undefined;
    }

    return {
      content: entries.items[0].fields.content,
      lastUpdated: DateTime.fromISO(entries.items[0].sys.updatedAt)
    };
  }

  public static async getRecipes(page: number): Promise<Recipe[]> {
    const query = CmsBackend.createRecipeQuery(`limit: ${page * 10}`);

    const graphqlUrl = CmsBackend.createGraphQlUrl();

    const response = await axios.post(
      graphqlUrl,
      JSON.stringify({ query }),
      {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_TOKEN}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data.data.recipeCollection.items.map((item: any) => this.recipeFromJSON(item));
  }

  public static async getRecipeDetails(recipeId: string): Promise<Recipe> {
    const query = CmsBackend.createRecipeQuery(`where: { slug: "${recipeId}" }, limit: 1`);

    const graphqlUrl = CmsBackend.createGraphQlUrl();

    const response = await axios.post(
      graphqlUrl,
      JSON.stringify({ query }),
      {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_TOKEN}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const contentfulRecipe = response.data.data.recipeCollection.items[0];
    const recipe = this.recipeFromJSON(contentfulRecipe);


    recipe.ingredients = contentfulRecipe.ingredientModifierCollection.items
      .map((item: any) => new Ingredient(
          item.amount,
          item.ingredient.name,
          item.unit.toLowerCase(),
          item.ingredient.allergens,
          item.sys.id,
          item.weightInOunces,
          item.weightInGrams,
        )
    );

    const idsToRetrieve = contentfulRecipe.instructionObjectCollection.items
      .map((item: any) => item.sys.id);

    const client = CmsBackend.createContentfulClient();

    const instructionEntries = await client.getEntries<any>({
      'sys.id[in]': idsToRetrieve.join(','),
      content_type: 'recipeInstruction',
      order: 'fields.order',
      include: 1,
    });

    recipe.setInstructions(
      instructionEntries.items.map((item: any) => new Instruction(
        item.fields.instruction,
        item.fields.order,
        undefined,
        item.fields.ingredientConnections === undefined ? [] :
        instructionEntries.includes.Entry
          .filter((instr: any) => item.fields.ingredientConnections.some((ingr: any) => ingr.sys.id === instr.sys.id))
          .map((instr: any) => instr.sys.id)
      )
    ));
    recipe.content = contentfulRecipe.blogContent.json;

    return recipe;
  }

  public static async searchRecipeTitles(search: string):
    Promise<{ title: string; slug: string; matchedOn: string[] }[]> {
    const typesenseClient = CmsBackend.createTypesenseClient();

    const searchParameters = {
      q: search,
      query_by: 'title,description,ingredients',
    };

    const result = await typesenseClient
      .collections('recipes')
      .documents()
      .search(searchParameters);

    const recipesToRetrieve = result.hits?.map((item: any) => ({
      title: item.document.title,
      slug: item.document.id,
      matchedOn: item.highlights.map((highlight: any) => highlight.field),
    }));
    return recipesToRetrieve ?? [];
  }

  public static async searchRecipes(search: string): Promise<any> {
    const typesenseClient = CmsBackend.createTypesenseClient();

    const searchParameters = {
      q: search,
      query_by: 'title,description,ingredients',
    };

    const result = await typesenseClient
      .collections('recipes')
      .documents()
      .search(searchParameters);

    const recipesToRetrieve = result.hits?.map((item: any) => `"${item.document.id}"`);

    const query = CmsBackend.createRecipeQuery(`
      limit: 20,
      where: { slug_in: [${recipesToRetrieve ?? [].join(',')}] }
    `, false);

    const graphqlUrl = CmsBackend.createGraphQlUrl();

    const response = await axios.post(
      graphqlUrl,
      JSON.stringify({ query }),
      {
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_TOKEN}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data.data.recipeCollection.items.map((item: any) => this.recipeFromJSON(item));
  }

  private static createTypesenseClient(): Client {
    const port = process.env.VUE_APP_TYPESENSE_PORT ?? 443;
    return new Client({
      nodes: [{
        host: process.env.VUE_APP_TYPESENSE_HOST ?? "",
        port: Number(port),
        protocol: process.env.VUE_APP_TYPESENSE_PROTOCOL ?? "https",
      }],
      
      apiKey: process.env.VUE_APP_TYPESENSE_API_KEY ?? "",
      connectionTimeoutSeconds: 30,
    });
  }

  private static createGraphQlUrl(): string {
    return `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE}/environments/${process.env.VUE_APP_CONTENTFUL_ENV}`;
  }

  private static createContentfulClient(): ContentfulClientApi {
    return createClient({
      space: process.env.VUE_APP_CONTENTFUL_SPACE ?? "",
      accessToken: process.env.VUE_APP_CONTENTFUL_TOKEN ?? "",
      environment: process.env.VUE_APP_CONTENTFUL_ENV || "main"
    });
  }

  private static createRecipeQuery(collectionFilter: string, includeDetails = true): string {
    return `
      {
        recipeCollection(${collectionFilter}) {
          total
          items {
            sys {
              id
              publishedAt
            }
            ${includeDetails ? 
              `blogContent { json }` :
              ``
            }
            title
            slug
            description
            servings
            servingSize
            coverPhoto {
              url
            }
            
            ingredientModifierCollection {
              total
              items {
                sys {
                  id
                }
                ingredient {
                  name
                  allergens
                }
                unit
                amount
                weightInGrams
                weightInOunces
              }
            }

            ${includeDetails ?
              `linkedFrom {
                recipeCommentCollection {
                  items {
                    comment
                    rating
                    name
                  }
                }
              }

              instructionObjectCollection {
                items {
                  sys {
                    id
                  }
                }
              }` :
              ``
            } 
            
          }
        }
      }
    `;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static recipeFromJSON(data: any): Recipe {
    return new Recipe(
      data.ingredientModifierCollection.total,
      data.coverPhoto.url,
      data.description,
      data.servings,
      data.servingSize,
      data.slug,
      data.title,
      undefined,
      DateTime.fromISO(data.sys.publishedAt),
    );
  }
}
