














import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import Breadcrumbs from '../components/Breadcrumbs.vue';
import RecipeBox from '../components/RecipeBox.vue';
import Recipe from '../types/recipe/Recipe';
import CmsBackend from '@/services/cms-backend';

@Component({
  filters: {
    relativeDate(value: DateTime): string {
      if (value.isValid) {
        return `(${value.toRelative()})`;
      }
      return '';
    },
  },
  components: {
    Breadcrumbs,
    RecipeBox,
  },
})
export default class RecipePage extends Vue {
  @Prop() private slug!: string;

  private recipe: Recipe|null = null;

  private mounted() {
    CmsBackend.getRecipeDetails(this.slug).then((recipe) => {
      this.recipe = recipe;
    });
  }

  get blogContent() {
    return documentToHtmlString(this.recipe?.content);
  }
}
