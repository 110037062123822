








import { Component, Vue } from 'vue-property-decorator';
// import { createClient } from 'contentful';
import AnalyticsService from './services/analytics-service';

import NavHeader from '@/components/NavHeader.vue';
import Footer from '@/components/Footer.vue';

@Component({
  components: {
    NavHeader,
    Footer,
  },
})
export default class App extends Vue {
  mounted() {
    const analyticsService = new AnalyticsService();
    analyticsService.init();
  }

}
