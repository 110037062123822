





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import pluralize from 'pluralize';

import Recipe from '@/types/recipe/Recipe';
import { Ingredient, VolumeUnit } from '@/types/recipe/Ingredient';
import { Instruction } from '@/types/recipe/Instruction';
import InstructionIngredient from './InstructionIngredient.vue';

@Component({
  components: { InstructionIngredient },
  filters: {
    removeExtraDecimal(value: string) {
      return value.replace('.0', '');
    }
  }
})
export default class RecipeBox extends Vue {
  @Prop() private recipe !: Recipe;

  private ingredients = this.recipe.ingredients;

  private scaledServings = this.recipe.servings;

  scaleTo(chosenScale ?: number) {
    if (chosenScale !== undefined) {
      this.scaledServings = chosenScale * this.recipe.servings;
      // deal with margins
      if (this.scaledServings == .99) {
        this.scaledServings = 1;
      }
    }

    this.ingredients = this.recipe.ingredients.map((ingredient: Ingredient) => 
    ingredient.withMultiplier(this.scale)
    );
  }

  formatIngredient(ingredient: Ingredient): string {
    const pluralized = pluralize(
      `${this.unitNumberToString(ingredient.unit.toString())}`,
      ingredient.amount,
    );
    return `${ingredient.unicodeAmount.replace(".0", "")} ${pluralized} ${ingredient.name}`
  }

  unitNumberToString(unitNumber: string) {
    const volUnit: VolumeUnit = Object.values(VolumeUnit)[Number(unitNumber)] as VolumeUnit;
    return volUnit.toString();
  }

  getIngredientById(id: String): Ingredient | undefined {
    return this.ingredients.find((ingredient) => ingredient.cmsId === id);
  }

  print() {
    window.print();
  }

  get scale() {
    return this.scaledServings / this.recipe.servings;
  }

  get instructions() {
    return this.recipe.instructions.sort((a: Instruction, b: Instruction) => a.order - b.order);
  }
}
