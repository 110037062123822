
<template>
<div class="self-center max-w-prose p-6">
  <h1 class="text-3xl">Privacy Policy</h1>
  <h2 class="text-2xl mt-5">Analytics</h2>
  <p class="text-base">
    Cats Coffee & Kitchen takes extra care to make sure that your privacy is protected. This website uses 
    <a class="underline text-primary" href="https://matomo.org/" target="_blank">Matomo</a> to track activity. This means your actions here are not sent to Google or
    any third party. These statistics are stored in a self-hosted database, so no one except the creators of
    this website can access that information. Full data ownership means that we aren't using your information for
    marketing or any other purpose.
  </p>
  <p class="mt-2">
    Have you noticed there aren't any dialogs asking for consent or storing cookies? That's because this website
    anonymizes all your data before we store it. This means none of the actions you take or the information we store
    can be traced to you. It's only used to make the website better, not to have any data that can be used to
    trace an individual. We anonymize IP addresses, and don't use any user IDs to make sure we aren't storing those pieces
    of personal identifying information. Keep in mind that in some cases, page urls and searches within this website are
    considered personally identifying information. This is data we keep because we feel it's important to know which pages
    you like most and which recipes you're searching for. You can prevent this being identifying information by not searching
    your name, postal code, or any other data about you.
  </p>
  <h2 class="text-2xl mt-5">What we Collect</h2>
  <p class="mt-2">
    We are collecting page clicks and actions you take on this website. That includes specific recipes, pages, and links.
    We track the browsers, devices and referrer of visitors. This helps us to know whether people are visiting us on their 
    phones or computers, and make sure that this website functions well on the ways you are viewing it. We keep track of the
    general location you are visiting from, and how you got to this website (from Google, Pintrest, etc). We don't share any 
    of this information, only using it to look at improvements we can make here. 
  </p>
  <h2 class="text-2xl mt-5">Cookies</h2>
  <p class="mt-2">
    We like to keep most of the cookies around here edible, so we store very few cookies on this website. We store some generic
    information like pages being viewed, the time in your timezone, and any links you click. These are all first-party cookies
    used to understand your return visits and what brought you here. We also store a cookie if you opt-out so we know not to keep
    any of your information or analytics.
  </p>
  <h2 class="text-2xl mt-5">Opt-Out of Analytics</h2>
  <p>
    We use analytics to make your experience here better. We care a lot about smartly tracking information, 
    so we totally understand that sometimes you want your actions to stay between you and no one else. In 
    that case, feel free to opt-out of analytics (or opt back in) here.
  </p>
  <button
    class="rounded bg-primary py-1 px-3 mt-3"
    @click="() => {
      if (isOptedOut)
        analyticsOptIn()
      else 
        analyticsOptOut()
    }"
  >{{ isOptedOut ? 'opt-in' : 'opt-out'}}
  </button>
  <p v-if="isOptedOut" class="border-2 border-primary rounded p-3 mt-3">
    Opt-out complete - your visits to this website will not be recorded by the Web Analytics tool. 
    Note that if you clear your cookies, delete the opt-out cookie,
    or if you change computers or Web browsers, you will need to perform the opt-out procedure again. 
  </p>
  </br>
  <div class="text-sm text-gray-500 mt-5 ml-1">last updated: Sept 28 2022</div>
</div>
</template>

<script>
import Vue from 'vue';

export default Vue.component('privacy-policy', {
  data() {
    return {
      isOptedOut: false,
      timer: null,
    };
  },
  mounted() {
    this.timer = setInterval(() => {
        this.isOptedOut = this.$matomo && this.$matomo.isUserOptedOut() || false;
    }, 1000);
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    analyticsOptOut() {
      this.$matomo && this.$matomo.optUserOut();
      this.isOptedOut = true;
    },
    analyticsOptIn() {
      this.$matomo && this.$matomo.forgetUserOptOut();
      this.isOptedOut = false;
    }
  }
});
</script>

<style scoped lang="scss">
</style>
