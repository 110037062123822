
export class Instruction {
  instruction: string;

  relatedImageUrl: string | null = null;

  relatedIngredient: string[] | null = null;

  order: number;

  constructor(
    instruction: string,
    order: number,
    relatedImageUrl?: string,
    relatedIngredient?: string[]
  ) {
    this.instruction = instruction;
    this.order = order;
    this.relatedImageUrl = relatedImageUrl || null;
    this.relatedIngredient = relatedIngredient || null;
  }

}
