



















import { Component, Prop, Vue } from 'vue-property-decorator';
import Recipe from '@/types/recipe/Recipe';

@Component
export default class RecipeCard extends Vue {
  @Prop() private recipe!: Recipe;
}
