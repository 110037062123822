export interface IngredientInterface {
  allergen: string|null;
  amount: number;
  name: string;
  unit: string;
  // eslint-disable-next-line camelcase
  weight_in_ounces: number;
  // eslint-disable-next-line camelcase
  weight_in_grams: number;
}

// eslint-disable-next-line no-shadow
export enum VolumeUnit {
  teaspoon = 0,
  tablespoon = 1,
  cup = 2,
  pinch = 3,
  each = 4,
  whole = 5,
  half = 6,
  slice = 7,
}

export class Ingredient {
  allergen: string[]|null = null;

  amount: number;

  cmsId: string | null = null;

  name: string;

  unit: VolumeUnit;

  weightInOunces: number|null = null;

  weightInGrams: number|null = null;

  constructor(
    amount: number,
    name: string,
    unit: string|VolumeUnit,
    allergen?: string[]|null,
    cmsId?: string|null,
    weightInOunces?: number|null,
    weightInGrams?: number|null,
  ) {
    this.amount = amount;
    this.name = name;
    if (typeof unit === 'string') {
      this.unit = VolumeUnit[unit as keyof typeof VolumeUnit];
    } else {
      this.unit = unit;
    }
    this.cmsId = cmsId || null;
    this.allergen = allergen || null;
    this.weightInOunces = weightInOunces || null;
    this.weightInGrams = weightInGrams || null;
  }

  public withMultiplier(multiplier: number): Ingredient {
    const updatedAmount = this.amount * multiplier;
    if (multiplier > 1) {
      return this.calculateLargerVolume(
        this.copy({ amount: updatedAmount }),
      );
    }

    return this.calculateSmallerVolume(
      this.copy({ amount: updatedAmount }),
    );
  }

  private calculateLargerVolume(ingredient: Ingredient): Ingredient {
    if (ingredient.unit === VolumeUnit.teaspoon && ingredient.amount >= 3) {
      const updatedIngredient = ingredient.copy(
        {
          amount: ingredient.amount / 3,
          unit: VolumeUnit.tablespoon,
        },
      );
      return this.calculateLargerVolume(updatedIngredient);
    } if (ingredient.unit === VolumeUnit.tablespoon && ingredient.amount >= 4) {
      const updatedIngredient = ingredient.copy(
        {
          amount: ingredient.amount / 4,
          unit: VolumeUnit.cup,
        },
      );
      return this.calculateLargerVolume(updatedIngredient);
    }

    return ingredient;
  }

  private calculateSmallerVolume(ingredient: Ingredient): Ingredient {
    if (ingredient.unit === VolumeUnit.cup && ingredient.amount < 0.25) {
      return this.calculateSmallerVolume(ingredient.copy({
        amount: ingredient.amount * 4,
        unit: VolumeUnit.tablespoon,
      }));
    } if (ingredient.unit === VolumeUnit.tablespoon && ingredient.amount < 1) {
      return this.calculateSmallerVolume(ingredient.copy({
        amount: ingredient.amount * 3,
        unit: VolumeUnit.teaspoon,
      }));
    }
    return ingredient;
  }

  get unicodeAmount(): string {
    if (this.amount > 1) {
      const divisibleAmounts = [0.13, 0.25, 0.33, 0.5, 0.67, 0.75];
      const decimalPortion = Math.round((this.amount % 1) * 100) / 100;
      if (divisibleAmounts.includes(decimalPortion)) {
        return `${Math.floor(this.amount).toString()} ${Ingredient.getUnicodeAmount(this.amount % 1)}`;
      } else if (decimalPortion > 0) {
        return this.amount.toPrecision(2);
      }
      return this.amount.toString();
    }
    return Ingredient.getUnicodeAmount(this.amount);
  }

  private static getUnicodeAmount(amount: number): string {
    switch (Math.round(amount * 100) / 100) {
      case 0.13:
        return '⅛';
      case 0.25:
        return '¼';
      case 0.33:
        return '⅓';
      case 0.5:
        return '½';
      case 0.67:
        return '⅔';
      case 0.75:
        return '¾';
      default:
        return amount.toPrecision(2);
    }
  }

  public copy(
    {
      amount,
      name,
      unit,
      allergen,
      cmsId,
      weightInOunces,
      weightInGrams,
    } : {
      amount?: number,
      name?: string,
      unit?: VolumeUnit,
      allergen?: string[]|null,
      cmsId?: string|null,
      weightInOunces?: number|null,
      weightInGrams?: number|null,
    },
  ): Ingredient {
    return new Ingredient(
      amount || this.amount,
      name || this.name,
      unit || this.unit,
      allergen || this.allergen,
      cmsId || this.cmsId,
      weightInOunces || this.weightInOunces,
      weightInGrams || this.weightInGrams,
    );
  }
}
