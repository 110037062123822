


















































import { Component, Vue } from 'vue-property-decorator';
import { Route, RouteConfig } from 'vue-router';

@Component
export default class Header extends Vue {
  private navOpen = false;

  private excludeRoute(route: Route): boolean {
    return route.meta?.showInNav !== undefined && route.meta.showInNav === false;
  }

  /**
   * Uses Vue Router's built in navigation function to do the navigating and also closes the
   * menu if it's open
   * @param navigate (route: Route) -> void. Navigates to route.
   * @param route the next route to navigate to
   */
  private navigateAndClose(navigate: any, route: Route) {
    navigate(route);
    this.navOpen = !this.navOpen;
  }

  private isCurrentRoute(route: string) {
    return this.$router.currentRoute.path === route;
  }

  get routes(): Array<{path: string, name: string}> {
    return this.$router.options?.routes?.filter((route: RouteConfig) => route.path !== '/')
      .filter((route: RouteConfig) => !route.path.replace('/', '').includes('/')) // filter out nested paths
      .map((route: RouteConfig) => ({
        path: route.path,
        name: route.path.replace('/', ''),
      })) || [];
  }
}
