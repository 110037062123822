import Vue from 'vue';
import { VueSvgIcon } from '@yzfe/vue-svgicon';
import { VueAutosuggest } from 'vue-autosuggest';
import VueMatomo from 'vue-matomo';
import VueMarkdown from 'vue-markdown';

import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import '@yzfe/svgicon/lib/svgicon.css';
import './main.css';

Vue.component('icon', VueSvgIcon);
Vue.component('vue-autosuggest', VueAutosuggest);
Vue.component('vue-markdown', VueMarkdown);

Vue.config.productionTip = false;


try {
  
  Vue.use(VueMatomo as any, {
    host: 'https://analytics.catscoffeeandkitchen.com',
    siteId: process.env.VUE_APP_ANALYTICS_ID,
    trackerFileName: 'matomo',
    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',
  
    // Overrides the autogenerated tracker script path entirely
    // Default: undefined
    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',
  
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    requireCookieConsent: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: true,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    // A list of pre-initialization actions that run before matomo is loaded
    preInitActions: [],
    trackSiteSearch: false,
    crossOrigin: undefined,
  });
} catch (ex) {
  console.error(ex);
}


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
