









import Vue from 'vue';
import { Ingredient, VolumeUnit } from '@/types/recipe/Ingredient';

export default Vue.component('instruction-ingredient', {
  props: {
    ingredient: Ingredient
  },
  methods: {
    unitNumberToString(unitNumber: string) {
      const volUnit: VolumeUnit = Object.values(VolumeUnit)[Number(unitNumber)] as VolumeUnit;
      return volUnit.toString();
    }
  }
});
