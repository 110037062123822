
<template>
<div class="self-center max-w-prose p-6">
  <div v-html="content"></div>
  <div class="text-sm text-gray-500 mt-5 ml-1">last updated: {{lastUpdated}}</div>
</div>
</template>

<script>
import Vue from 'vue';
import CmsBackend from '../services/cms-backend';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { DateTime } from 'luxon';

export default Vue.component('about-this-website', {
  data() {
    return {
      content: '',
      lastUpdated: DateTime.local().toLocaleString(),
    }
  },
  mounted() {
    CmsBackend.getTextContent(this.$router.currentRoute.path.replace('/', '')).then((item) => {
      this.content = documentToHtmlString(item.content);
      this.lastUpdated = item.lastUpdated.toLocaleString(); 
    });
  },
});
</script>

<style>

</style>
