


















/* eslint-disable class-methods-use-this */
import { Component, Vue } from 'vue-property-decorator';

import Recipe from '@/types/recipe/Recipe';
import RecipeCard from '../components/RecipeCard.vue';
import CmsBackend from '@/services/cms-backend';
import RecipeSearchbar from '@/components/RecipeSearchbar.vue';

@Component({
  components: {
    RecipeCard,
    'recipe-searchbar': RecipeSearchbar,
  },
})
export default class Recipes extends Vue {
  private timeout: NodeJS.Timeout | null = null;

  private suggestions: {data: string[]}[] = [];

  private debounceMilliseconds = 250;

  private mounted() {
    this.$store.dispatch('getRecipes');
  }

  private searchRecipes(search: any) {
    this.$store.dispatch('searchRecipes', search);
  }

  private fetchResults(search: string) {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      CmsBackend.searchRecipeTitles(search).then((values: any) => {
        this.suggestions = [];
        // this.selected = null;

        if (values.length > 0) {
          this.suggestions.push({ data: values });
        }
      });
    }, this.debounceMilliseconds);
  }

  private navigateToRecipe(suggestionItem: any, index: number) {
    this.$router.push({ path: `recipes/${suggestionItem.item.slug}` });
  }

  get recipes(): Recipe[] {
    return this.$store.state.recipes;
  }
}
